(function($) {

    $(window).on('load', function () {



        // so galleries
        $('.gallery').addClass('view-animation');

        // Components loading animations
        $('.view-animation').viewportChecker({
            classToAdd: 'animated',
            offset: 20
        });

       $('.header-hamburger').click(function(){
            responsiveAnnouncementBar();
            $('body').toggleClass('menu-open');
        });

        // Accordion for FAQs (jQuery)
        $('.accordion dt.active + dd').slideDown();

        $('.accordion').on('click', 'dt', function () {
            $('.accordion dd').slideUp();

            if (!$(this).hasClass('active')) {
                // remove active class from any others that might be active
                $('.accordion dt.active').removeClass('active');
                $(this).addClass('active');
                $(this).next().slideDown();
            } else {
                $(this).removeClass('active');
            }
        });

        // Lazy load instagram feed module
        var hasLoadedModules = false;
        $(window).scroll(function(){
            if(!hasLoadedModules && $(this).scrollTop() > 50){
                hasLoadedModules = true;
                loadLazyModules();
            }
        });

        function loadLazyModules(){
            var lzmodules_el = $('[data-lzmodule]');
            var lzmodules = [];

            lzmodules_el.each(function(){
                lzmodules.push($(this).data('lzmodule'));
            });

            $.ajax({
                url: ajaxurl,
                type: 'post',
                data:{
                    action: 'load_modules',
                    modules: lzmodules,
                    pageid: pageid
                }
            }).done(function(data){
                var response = JSON.parse(data);

                lzmodules_el.each(function(){
                    $(this).replaceWith(response[$(this).data('lzmodule')]);

                    if($(this).data('lzmodule') == 'section-instagram'){
                        instaSlider();
                    }

                    if($(this).data('lzmodule') == 'section-socialtestimonials'){
                        initSocialTestimonials();
                    }
                });
            });
        }

        $(document).on('click', '.triggersubnav, .menu-item-has-children > a[href="#"]', function(e){
            e.preventDefault();
            e.stopPropagation();

            var thissubnav = $(this).closest('li');
            var thisnav = $(this).closest('.header-menu');

            thissubnav.addClass('active');

            if(thisnav.hasClass('secondary')){
                thisnav.addClass('tertiary');
            }else{
                thisnav.addClass('secondary');
            }

            calcNavHeight();
        });


        $('.backsubnav').click(function(e){
            e.preventDefault();
            var thisnav = $(this).closest('.header-menu');
            var thissubnav = $(this).closest('.sub-menu').parent();

            thissubnav.removeClass('active');

            if(thisnav.hasClass('tertiary')){
                thisnav.removeClass('tertiary');
            }else{
                thisnav.removeClass('secondary');
            }

            calcNavHeight();
        });

        setTimeout(function(){
            var wst = $(window).scrollTop();
            var wh = $(window).height();
            var ste = $('.scrolltotop');
            var fte = $('.site-footer');
            var fteo = fte.offset().top;

            $(window).on('scroll', function(){
                wst = $(window).scrollTop();

                if(wst > 600){
                   ste.addClass('shown');
                }else{
                    ste.removeClass('shown');
                }

                if(wst > (fteo - wh)){
                    ste.addClass('after-footer');
                }else{
                    ste.removeClass('after-footer');
                }
            });

            $(window).resize(function(){
                wst = $(window).scrollTop();
                wh = $(window).height();
                fte.offset().top;
            }).resize();
        }, 1500);

        // once widgets created, load the JS

        // Create MB Widgets
        var mbscriptadded = false;
        var mbtemplate = '<healcode-widget data-type="appointments" data-widget-partner="object" data-widget-id="##ID##" data-widget-version="0" ></healcode-widget>';
        var mbcontainer = '<div class="modal fade mindbody" tabindex="-1" role="dialog" id="##ID##">' +
                                '<div class="modal-dialog modal-dialog-centered" role="document">' +
                                    '<div class="modal-content">' +
                                        '<span data-dismiss="modal">+</span>' +
                                        '##CONTENT##' +
                                        '<span class="loading">Please wait...</span>'+
                                    '</div>' + 
                                '</div>' +
                            '</div>';

        $('a[href*="#mindbody_"]').each(function(){
            var mbstring = mbtemplate;
            var mbhtml = mbcontainer;
            var mbid = $(this).attr('href').split('#mindbody_')[1];

            if(!mbid || mbid == 'BLANK'){
                mbid = siteurl+'/contact-us/';
                $(this).attr('href', mbid);
                $(this).attr('target', '');
            }else{
                mbstring = mbstring.replace('##ID##', mbid);
                mbhtml = mbhtml.replace('##ID##', mbid);
                mbhtml = mbhtml.replace('##CONTENT##', mbstring);

                $('body').append(mbhtml);

                $(this).click(function(e){
                    e.preventDefault();

                    if(!mbscriptadded){
                        $('body').append('<script src="https://widgets.mindbodyonline.com/javascripts/healcode.js"></script>');
                        mbscriptadded = false;
                    }

                    $('#'+mbid).modal(true);
                });
            }
        });


        $('.member-tabs .tab-title').click(function(e){
            e.preventDefault();
            var thisparent = $(this).closest('.member-tabs');
            var activeslides = $('.active', thisparent);
            var thistarget = $($(this).attr('href'));

            activeslides.removeClass('active');
            $(this).addClass('active');
            thistarget.addClass('active');
        });

        if(window.location.href.indexOf('#') > -1){
            var hash = window.location.href.split('#')[1];

            if(hash){
                var hashel = $('#'+hash);
                var hashsection;

                if(hashel.length){
                    setTimeout(function(){
                        if(hashel.hasClass('tab-panel')){
                            hashsection = hashel.closest('section');

                            $('html, body').animate({
                                scrollTop: hashsection.offset().top - $('.site-header').outerHeight()
                            }, 1500);

                            $('a[href="#'+hash+'"]').click();
                        }
                    }, 500);
                }
            }
        }

        if($('#countdown').length){
            var timeduration = $('#countdown').data('duration');
            startTimer(timeduration, $('#countdown')[0]);
        }

        // Inline Video Funcionality
        $(document).on('click', '.inline-video-trigger', function () {
            if ($(this).data('video-id')) {
                if ($(this).hasClass('vimeo')) {
                    var iframeHTML = '<iframe src="https://www.vimeo.com/embed/' + $(this).attr('data-video-id') + '?title=0&byline=0&portrait=0?&autoplay=1" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>';
                } else {
                    var iframeHTML = '<iframe src="https://www.youtube.com/embed/' + $(this).attr('data-video-id') + '?autoplay=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
                }

                $(this).parent('.video-preview-container').find('.inline-video-trigger').hide();
                $(this).parent('.video-preview-container').find('.overlay').hide();
                $(this).parent('.video-preview-container').find('iframe').remove();
                $(this).parent('.video-preview-container').append(iframeHTML);
            } else {
                console.error('no video ID provided.');
            }
        });

        // Phone Concatenation Script For Tracking
        setTimeout(function () {
            $('.header-phone em').each(function () {
                var unsliced = $(this).text();
                var sliced = unsliced.slice(0, -2) + "...";
                $(this).text(sliced);
                var linked = "tel:" + unsliced.replace(/\s/g, '');
                $(this).click(function () {
                    if ($(window).width() < 1000) {
                        window.location.href = linked;
                    } else {
                        $(this).text(unsliced);
                    }
                });
            });

        }, 3000);

        $('.section-testimonials .slider').each(function(){

            if($('.slide',this).length > 1){
                $(this).slick({
                    infinite: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    speed: 600,
                    dots: true,
                    arrows: false,
                    autoplay: true,
                    autoplaySpeed: 5000,
                });
            }
        });

        $('.section-beforeafter .slider').each(function(){
            var thissection = $(this).closest('section');
            var thisslider = $(this);

            thisslider.slick({
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                speed: 600,
                dots: false,
                autoplay: true,
                autoplaySpeed: 5000,
                draggable: false,
                swipe: false,
                prevArrow: $('.prev-arrow', thissection),
                nextArrow: $('.next-arrow', thissection),
            });

            setTimeout(function(){
                $('.comparison-wrap', thisslider).each(function(){
                    $(this).twentytwenty({
                        no_overlay: true
                    });
                });
            }, 300);
        });

        $('.section-videoslider .slider').each(function(){
            var thisslider = $(this);
            var thissection = $(this).closest('section');

            thisslider.slick({
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                speed: 600,
                dots: false,
                autoplay: true,
                autoplaySpeed: 5000,
                prevArrow: $('.prev-arrow', thissection),
                nextArrow: $('.next-arrow', thissection),
            });
        });

        // comparison outside slider
        $('.compare-grid .comparison-wrap').each(function(){
            $(this).twentytwenty({
                no_overlay: true
            });
        });

        initSocialTestimonials();
        function initSocialTestimonials(){
            $('.section-socialtestimonials .slider').each(function(){
                var thissection = $(this).closest('section');

                if($('.slide',this).length > 1){
                    $(this).slick({
                        infinite: true,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        speed: 600,
                        dots: false,
                        autoplay: true,
                        autoplaySpeed: 5000,
                        prevArrow: $('.slider-nav .prev-arrow', thissection),
                        nextArrow: $('.slider-nav .next-arrow', thissection),
                    });

                    $(this).next('.mobile-slider').slick({
                        infinite: true,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        speed: 600,
                        dots: false,
                        autoplay: false,
                        autoplaySpeed: 5000,
                        adaptiveHeight:true,
                        prevArrow: $('.slider-nav-mobile .prev-arrow', thissection),
                        nextArrow: $('.slider-nav-mobile .next-arrow', thissection),
                    });
                }
            });
        }

        $('.section-productslider .slider').each(function(){
            var thissection = $(this).closest('section');

            if($('.slide',this).length > 1){
                $(this).slick({
                    infinite: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    speed: 600,
                    dots: false,
                    autoplay: true,
                    autoplaySpeed: 15000,
                    prevArrow: $('.prev-arrow', thissection),
                    nextArrow: $('.next-arrow', thissection),
                    mobileFirst: true,
                    responsive: [ 
                        {
                            breakpoint: 992,
                            settings: {
                                slidesToShow: 2,
                            }
                        }
                    ]
                }).on('beforeChange', function(event, slick, currentSlide, nextSlide){
                    var slidesLength = slick.$slides.length - 1,
                        isCurrentFirstOrLast = currentSlide === 0 || currentSlide === slidesLength,
                        isNextFirstOrLast = nextSlide === 0 || nextSlide === slidesLength;
                        
                    if (isCurrentFirstOrLast && isNextFirstOrLast){
                      var nextClone = $(event.currentTarget).find('.slick-cloned.slick-active');
                      setTimeout(function(){
                        nextClone.addClass('slick-current');
                      }, 100)
                    }
                });
            }
        });

        instaSlider();
        function instaSlider(){
            // give them IDs to prevent errors
            $('#sbi_images').on('init', function(){
                if($('.sbi_item').length){
                    var idcount = 0;
                    $('.sbi_item').each(function(){
                        idcount++;
                        if(!$(this).attr('id')){
                            $(this).attr('id','sbinew'+idcount);
                        }
                    });
                }
            });
            $('#sbi_images').slick({
                infinite: true,
                slidesToShow: 2,
                slidesToScroll: 2,
                speed: 600,
                dots: false,
                arrows: false,
                autoplay: true,
                autoplaySpeed: 5000,
                mobileFirst: true,
                responsive: [ 
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 5,
                        }
                    },
                    {
                        breakpoint: 400,
                        settings: {
                            slidesToShow: 3,
                        }
                    },
                ]
            });
        }

    });

    var resizeTimer;
    $(window).bind('resize load', function () {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(function () {
           responsiveAnnouncementBar();
        }, 100);
    });

    
    $('.seoreadmore').click(function(e){
        e.preventDefault();
        var thisSeoContainer = $('.seo-container', $(this).closest('section'));
        $(this).hide();
        thisSeoContainer.show();
        $('html,body').animate({scrollTop: thisSeoContainer.offset().top - $('.site-header').outerHeight()});
    }); 
    $('.seoreadless').click(function(e){
        e.preventDefault();
        var thisSeoContainer = $('.seo-container', $(this).closest('section'));
        $('.seoreadmore').show();
        thisSeoContainer.hide();
        $('html,body').animate({scrollTop: $(this).closest('section').offset().top});
    }); 


    // Sticky Header
    $(window).on("scroll load", function () {
        if ($(window).scrollTop() >= 100) {
            $('.site-header').addClass('scrolled');
        } else if($('.site-header').hasClass('scrolled')) {
            $('.site-header').removeClass('scrolled');

            setTimeout(function(){
                responsiveAnnouncementBar();
            }, 600);
        }
    });

    $(document).on('click', 'a[href*="#"]:not(.tab-title):not([href="#seoblock"]):not(.page-numbers):not([href*="#mindbody_"])', function (event) {
        if($(this).attr('href') == '#'){
            event.preventDefault();
            return true;
        }

        var target = $($(this).attr('href'));
        
        if(!$(target).length){
            return true;
        }

        event.preventDefault();

        if($(this).attr('href') == "#top"){
            $('html, body').animate({
                scrollTop: 0
            }, 1500);

            return;
        }

        if(target.length){
            target = $(target).closest('section');
        }

        if(target.find('form').length){
            target = target.find('form');
        }

        if (target.length) {
            $('html, body').animate({
                scrollTop: target.offset().top - $('.site-header').outerHeight()
            }, 1500);
        }
    });

    var popSearchOpen = false;
    $('a[href="#seoblock"]').click(function(e){
        e.preventDefault();

        popSearchOpen = !popSearchOpen;

        if(popSearchOpen){
           $(this).text('Hide Popular Searches');
        }else{
           $(this).text('Popular Searches');
        }

        $('#seoblock').slideToggle();
    });

    var wpcf7Elm = document.querySelector( '.wpcf7' );
    if(wpcf7Elm){
        wpcf7Elm.addEventListener( 'wpcf7mailsent', function( event ) {
            if(wpcf7Elm.querySelector('[name="redirect"]')){
                var redirect = wpcf7Elm.querySelector('[name="redirect"]').value;
                window.location.href = redirect;
            }
        }, false );
    }

    var hasOpenedModal = false;
    if(!getCookie('nlpopup')){
        setTimeout(function(){
            document.addEventListener("mouseout", function(evt){
                if(!evt.toElement && !evt.relatedTarget && !hasOpenedModal) {
                    $('body').addClass('newsletter-modal');
                    hasOpenedModal = true;
                    setCookie('nlpopup', true, 1);
                }
            });
        }, 3000);

        $('body').click(function(e){
            var target = e.target;
            if(!$(target).closest('.modal-newsletter__inner').length){
                $('body').removeClass('newsletter-modal');
            }
        });

        $('.modal-newsletter__close').click(function(){
            $('body').removeClass('newsletter-modal');
        });
    }

    function responsiveAnnouncementBar(){
        var anbarHeight = $('.site-header__accouncements').outerHeight();
        var headerHeight = $('.site-header').outerHeight();
        var headerStyleDiv = $('#header-styles');

        var newStyles = ''+
        '.site-header.scrolled.anbar{'+
            'transform: translateY(-'+anbarHeight+'px);'+
        '}';

        newStyles += ''+
        '.site-content.anbar{'+
            'padding-top: '+headerHeight+'px;'+
        '}';

        newStyles += ''+
        '.anbar .site-header__inner .menu-container{'+
            'height: calc(100vh - ' + headerHeight +'px);'+
            'top: '+ headerHeight + 'px'+
        '}';

        newStyles += ''+
        '.anbar.scrolled .site-header__inner .menu-container{'+
            'height: calc(100vh - ' + (headerHeight - anbarHeight) +'px);'+
            'top: '+ headerHeight + 'px'+
        '}';

        headerStyleDiv.html(newStyles);
    }

    function calcNavHeight(){
        var nb = $('.nav-box');
        var hm = $('.header-menu');
        var asn = '';

        if(hm.hasClass('tertiary')){
            asn = $('.nav > li.active > .sub-menu > li.active .sub-menu');
        }else if(hm.hasClass('secondary')){
            asn = $('.nav > li.active > .sub-menu');
        }else{
            asn = $('.nav');
        }

        nb.height(asn.outerHeight());
    }

    function startTimer(duration, display) {
        var timer = duration;
        var minutes, seconds, hours, days;
        var hasResized = false;
        
        setInterval(function () {
            minutes = parseInt(timer / 60, 10) % 60;
            seconds = parseInt(timer % 60, 10);

            hours = parseInt(timer / 60 / 60, 10) % 24;
            days = parseInt(timer / 60 / 60 / 24, 10);

            days = days < 10 ? "0" + days : days;
            hours = hours < 10 ? "0" + hours : hours;
            minutes = minutes < 10 ? "0" + minutes : minutes;
            seconds = seconds < 10 ? "0" + seconds : seconds;

            if(days > 0){
                display.textContent = days + ":" + hours + ":" + minutes + ":" + seconds;
            }else{
                display.textContent = hours + ":" + minutes + ":" + seconds;
            }

            if (--timer < 0) {
                timer = 0;
            }

            if(!hasResized){
                hasResized = true;
                responsiveAnnouncementBar();
            }
        }, 1000);
    }

    function setCookie(name,value,days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days*24*60*60*1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "")  + expires + "; path=/";
    }
    function getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    }

})( jQuery );